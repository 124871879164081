<template>
  <div>
    <div
      class="modal fade"
      id="modal-form-tep-inspeccion"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Inspección</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card-body p-0">
              <div v-if="inspecciones.length > 0">
                <table
                  class="table table-striped table-bordered table-hover table-md"
                >
                  <thead class="thead-dark">
                    <tr>
                      <th>Inspeccion</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody v-if="inspecciones[0].inspecciones.length > 0">
                    <tr
                      v-for="item of inspecciones[0].inspecciones"
                      :key="item.id"
                    >
                      <td>{{ item.nombre }}</td>
                      <td>
                        <button
                          v-if="turnoInspecciones === undefined"
                          type="button"
                          class="btn btn-info float-lg-right"
                          style="cursor: pointer"
                          @click="inspeccion(turno, item)"
                        >
                          <i class="fas fa-plus-square"></i>
                        </button>
                        <button
                          v-else
                          type="button"
                          class="btn btn-danger float-lg-right"
                          style="cursor: pointer"
                          @click="edit()"
                        >
                          <i class="fa fa-binoculars"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-else>
                    <td>Inspección preoperacional</td>
                    <td>
                      <button
                        v-if="turnoInspecciones !== undefined"
                        type="button"
                        class="btn btn-danger float-lg-right"
                        style="cursor: pointer"
                        @click="edit()"
                      >
                        <i class="fa fa-binoculars"></i>
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-info float-lg-right"
                        style="cursor: pointer"
                        @click="inspeccionPreoperacional(turno)"
                      >
                        <i class="fas fa-plus-square"></i>
                      </button>
                    </td>
                  </tbody>
                </table>
              </div>

              <div v-else>
                <table
                  class="table table-striped table-bordered table-hover table-md"
                >
                  <thead class="thead-dark">
                    <tr>
                      <th>Inspeccion</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <td>Inspección preoperacional</td>
                    <td>
                      <button
                        v-if="turnoInspecciones !== undefined"
                        type="button"
                        class="btn btn-danger float-lg-right"
                        style="cursor: pointer"
                        @click="edit()"
                      >
                        <i class="fa fa-binoculars"></i>
                      </button>
                      <button
                        v-else
                        type="button"
                        class="btn btn-info float-lg-right"
                        style="cursor: pointer"
                        @click="inspeccionPreoperacional(turno)"
                      >
                        <i class="fas fa-plus-square"></i>
                      </button>
                    </td>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "TepTurnoInspeccion",
  components: {},
  data() {
    return {
      inspecciones: {},
      turnoInspecciones: {},
      turno_id: null,
      turno: null,
      accion: "",
      metodo: "",
      tipo: null,
    };
  },
  methods: {
    cargarInspecciones() {
      let me = this;
      axios
        .get(
          "/api/tep/turno/cargarInspeccion?turno_id=" +
            this.turno_id +
            "&tipo=" +
            this.tipo
        )
        .then(async (response) => {
          me.$parent.cargando = false;
          let res = response.data;

          if (res.Msg == "error") {
            //this.$refs.closeModal.click();
            /* this.$swal({
              icon: "error",
              title:
                "No se puede realizar inspeccion hasta que la documentacion cumpla",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            }); */
          }
          me.turnoInspecciones = res.data[0];
        });
    },
    getInspecciones(sitio_id) {
      let me = this;
      me.$parent.cargando = true;
      axios
        .get("/api/tep/turno/inspeccionViaje?sitio_id=" + sitio_id)
        .then((response) => {
          me.inspecciones = response.data;
        });
    },

    edit() {
      let inspeccion = this.turnoInspecciones.id;
      this.$refs.closeModal.click();
      return this.$router.push({
        name: "/Tep/InspeccionForm",
        params: { inspeccion_id: inspeccion },
      });
    },

    inspeccion(turno, inspeccion) {
      this.$refs.closeModal.click();
      return this.$router.push({
        name: "/Tep/InspeccionForm",
        params: {
          turno: turno,
          inspeccion: inspeccion,
        },
      });
    },

    inspeccionPreoperacional(turno) {
      this.$refs.closeModal.click();
      return this.$router.push({
        name: "/Tep/InspeccionForm",
        params: {
          turno: turno,
        },
      });
    },

    async llenar_modal_inspeccion(turno, tipo) {
      this.turno_id = turno.id;
      this.turno = turno;
      this.tipo = tipo;
      await this.getInspecciones(this.turno.sitio_id);
      await this.cargarInspecciones();
    },
  },
  mounted() {},
};
</script>
